<template>
  <v-form>
    <div class="filterField">
      <v-form>
        <v-row>
          <v-col cols="12" md="12">
            <label class="form-label">{{ $t('profile.companyName') }}</label>
            <v-text-field
              hide-details="auto"
              v-model="userDetail.company_name"
              :error-messages="companyNameError"
              :placeholder="$t('profile.companyNamePlaceholder')"
              @blur="$v.userDetail.company_name.$touch()"
              solo dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <label class="form-label">{{ $t('profile.companyEmail') }}</label>
            <v-text-field
              hide-details="auto"
              v-model="userDetail.company_email"
              :error-messages="companyEmailError"
              :placeholder="$t('profile.companyEmailPlaceholder')"
              @blur="$v.userDetail.company_email.$touch()"
              solo dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <label class="form-label">{{ $t('profile.companyPhone') }}</label>
            <v-text-field
              hide-details="auto"
              maxlength="14"
              v-model="userDetail.company_phone"
              :error-messages="companyPhoneError"
              :placeholder="$t('profile.companyPhonePlaceholder')"
              @blur="$v.userDetail.company_phone.$touch()"
              v-to-us-format
              solo dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <label class="form-label">{{ $t('profile.representativeName') }}</label>
            <v-text-field
              hide-details="auto"
              v-model="userDetail.representative_name"
              :error-messages="representativeNameError"
              :placeholder="$t('profile.representativeNamePlaceholder')"
              @blur="$v.userDetail.representative_name.$touch()"
              solo dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <label class="form-label">{{ $t('profile.representativePhone') }}</label>
            <v-text-field
              hide-details="auto"
              maxlength="14"
              v-model="userDetail.representative_phone"
              :error-messages="representativePhoneError"
              :placeholder="$t('profile.representativePhonePlaceholder')"
              @blur="$v.userDetail.representative_phone.$touch()"
              v-to-us-format
              solo dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="12">
            <label class="form-label">{{ $t('profile.address') }}</label>
            <GooglePlace
              id="address"
              class="customer-address"
              :isRequired="true"
              placeholder="profile.address"
              validationMsg="profile.validations.addressIsRequired"
              :address="userDetail.address"
              @onAddressSelect="addressHandler"
              dense solo
            />
          </v-col>

          <v-col cols="12" md="3">
            <label class="form-label">{{ $t('profile.zipCode') }}</label>
            <v-text-field
              hide-details="auto"
              type="number"
              v-model="userDetail.zip_code"
              :error-messages="zipCodeError"
              @blur="$v.userDetail.zip_code.$touch()"
              :placeholder="$t('profile.zipCodePlaceholder')"
              solo dense
              maxlength="5/"
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="3">
            <label class="form-label">{{ $t('profile.city') }}</label>
            <v-text-field
              hide-details="auto"
              v-model="userDetail.city"
              :error-messages="cityError"
              @blur="$v.userDetail.city.$touch()"
              :placeholder="$t('profile.cityPlaceholder')"
              solo dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="6">
            <label class="form-label">{{ $t('profile.state') }}</label>
            <v-text-field
              hide-details="auto"
              v-model="userDetail.state"
              :error-messages="stateError"
              :placeholder="$t('profile.statePlaceholder')"
              @blur="$v.userDetail.state.$touch()"
              solo dense
            ></v-text-field>
          </v-col>

          <v-col cols="12" md="12" v-if="user.user_type == 3">
            <label class="form-label">{{ $t("coreBuying.companyLogo") }}</label>
            <div
              class="flex w-full h-screen items-center justify-center text-center rd-fileUpload"
            >
              <v-progress-circular
                v-if="imageLoader"
                indeterminate
                :size="30"
                color="green"
              ></v-progress-circular>
              <div class="" v-if="!imageLoader">
                <image-uploader
                  ref="logo"
                  id="fileInput"
                  :quality="0.9"
                  outputFormat="verbose"
                  :preview="false"
                  :className="['d-none']"
                  @input="onFileChange($event, 'logo')"
                >
                  <label
                    for="fileInput"
                    slot="upload-label"
                    class="node-image-uploader"
                  >
                    <span
                      class="upload-caption"
                      >{{ $t("posts.clickToUpload") }}</span
                    >
                  </label>
                </image-uploader>
              </div>
            </div>
            <div v-if="image" id="logo" class="select-image">
                <img  class="logo" :src="image?.url100" alt="" />
              <a
                id="logo-delIcon"
                v-if="image"
                @click="deleteImage('logo')"
                class="delicon"
                data-tooltip="Delete"
              >
              <v-icon>mdi-delete</v-icon>
              </a>
              </div>
          </v-col>

          <v-col cols="12" md="12">
            <v-btn
              :loading="loading"
              hide-details="auto"
              height="45"
              :disabled="$v.$invalid && $v.$anyDirty && loading"
              class="btn customBtn pattern-btn white--text text-capitalize btn-submit font-16"
              @click="update"
            >
              <span>{{ $t("profile.submit") }}</span>
              <template v-slot:loading>
                <v-icon>mdi-loading</v-icon>
              </template>
            </v-btn>
          </v-col>
        </v-row>
      </v-form>
    </div>
  </v-form>
</template>

<script>
import { required, email, maxLength } from "vuelidate/lib/validators";
import numberUtility from "@/utils/number.utility";
import ProfileMixin from "@/mixins/ProfileMixin";

export default {
  components: {
    GooglePlace: () => import("@/components/common/GooglePlace"),
  },

  mixins: [ProfileMixin],

  data() {
    return {
      loading: false,
      address: null,
      userDetail: {},
      validAddress: true,
      image: false,
      imageLoader: false,
    };
  },
  validations: {
    userDetail: {
      company_name: {
        required,
        maxLength: maxLength(20),
      },
      company_email: {
        required,
        email,
      },
      company_phone: {
        required,
        usFormat: (value) => {
          return /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/.test(value);
        },
      },
      representative_name: {
        required,
        maxLength: maxLength(20),
      },
      representative_phone: {
        required,
        usFormat: (value) => {
          return /^(\([0-9]{3}\) |[0-9]{3}-)[0-9]{3}-[0-9]{4}$/.test(value);
        },
      },
      zip_code: {
        required,
      },
      state: {
        required,
      },
      city: {
        required,
      },
    },
  },
  mounted() {
    if (this.user && this.user.user_detail) {
      this.userDetail = this.user.user_detail;
      this.userDetail.user_type = this.user.user_type;
      this.userDetail.representative_phone = numberUtility.toUsPhone(
        this.userDetail.representative_phone
      );
      this.userDetail.company_phone = numberUtility.toUsPhone(
        this.userDetail.company_phone
      );
    }
    if (this.user) {
      this.image = this.user?.medias[0];
    }
  },
  methods: {
    addressHandler(payload) {
      this.userDetail.address = payload.address;
      this.userDetail.state = payload.state;
      this.userDetail.zip_code = payload.zip;
      this.userDetail.city = payload.city;
      this.validAddress = payload.valid;
    },
    async onFileChange(fileData, type) {
      var selectedImage = await this.saveImage(fileData.dataUrl, type);
      this.image = selectedImage.data;
    },
    async saveImage(file, tag) {
      try {
        this.imageLoader = true;
        let formData = new FormData();
        await formData.append("file", file);
        await formData.append("tag", tag);
        await formData.append("company_name", "");
        await this.uploadImage(formData);
        this.imageLoader = false;
        return this.imageResponse;
      } catch ({ message }) {
        this.apiErrorMessage = message;
      }
    },

    deleteImage(type) {
      this.$refs[type].$el.children[1].value = "";
      this.image = false;
      if (this.isAttentionPost) {
        this.isAttentionPost = false;
      }
    },
  },
};
</script>
