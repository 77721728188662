<template>
  <div class="rnd-profile">
    <div class="panel-inr-box">
      <div class="box-top-wrap d-flex align-center justify-space-between">
        <div>
          <h3 class="box-title green-text">
            <span> {{ $t("profile.title") }}</span>
          </h3>
        </div>
      </div>
      <div class="custom-card vehicle-car inside-model-box">
        <div class="custom-box">
          <Associate v-if="isAssociate" />
          <Member v-else />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Member from "../components/profile/Member";
import Associate from "../components/profile/Associate";

export default {
  name: "Profile",
  components: { Associate, Member },
  data() {
    return {
      address: null,
      userDetail: {},
      validAddress: true,
    };
  },
  computed: {
    ...mapGetters({
      user: "user/getUser",
    }),
    isAssociate() {
      return !!this.user.parent_id;
    },
  },
  mounted() {
    if (this.user && this.user.user_type != 1 && this.user.user_type != 3) {
      this.$router.push({ name: "MembersDirectory" });
    }
  },
  methods: {},
};
</script>
